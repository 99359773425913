<template>
  <vx-card style="padding-left: 10px">
    <div class="vx-col lg:w-1/2" align="left">
    </div>

    <div id="data-list-list-view" class="data-list-container">
      <vs-table
        ref="table"
        :sst="true"
        :total="totalDocs"
        :data="invoiceData"
        @search="handleSearch"
        @change-page="handleChangePage"
        @sort="handleSort"
        :max-items="dataTableParams.limit"
        search
        :noDataText="noDataText"
      >
        <div slot="header" class="flex flex-wrap-reverse flex-grow justify-between">
          <div style="float: left">
            <vs-select
              placeholder="10"
              vs-multiple
              autocomplete
              v-model="dataTableParams.limit"
              class="mr-6"
            >
              <vs-select-item
                :key="index"
                :value="item.value"
                :text="item.text"
                v-for="(item,index) in limitOptions"
                :clearable="false"
              />
            </vs-select>
          </div>
        </div>

        <template slot="thead">
          <vs-th sort-key="invoiceNumber">Invoice Number</vs-th>
          <vs-th>Clinic Name</vs-th>
          <vs-th>Year</vs-th>
          <vs-th>Month</vs-th>
          <vs-th sort-key="treatmentCount">No of treatments</vs-th>
          <vs-th>Payment Status</vs-th>
          <vs-th>Action</vs-th>
        </template>

        <template slot-scope="{ data }">
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :data="data[indextr].invoiceNumber">
              {{
                data[indextr].invoiceNumber
              }}
            </vs-td>
            <vs-td :data="data[indextr].clinicName[0] ">
              <div v-if="data[indextr].clinicName.length > 0">
              {{ data[indextr].clinicName[0] | capitalize }}
              </div>
              <div v-else>N/A</div>
            </vs-td>
            <vs-td :data="data[indextr].year">
              {{
                data[indextr].year
              }}
            </vs-td>
            <vs-td :data="data[indextr].month">
              {{
                data[indextr].month
              }}
            </vs-td>
            <vs-td :data="data[indextr].treatmentCount">
              {{
                data[indextr].treatmentCount
              }}
            </vs-td>
            <vs-td :data="data[indextr].voidEntirePayment">
              <p v-if="data[indextr].voidEntirePayment == false">
                Scheduled for {{
                moment(data[indextr].startDate).set("date",7).add(1, "months").format("DD/MM/YY")
                }}</p>
              <p v-if="data[indextr].voidEntirePayment == true">
                Cancelled
              </p>
            </vs-td>
            <vs-td :data="data[indextr].invoiceId">
              <vx-tooltip text="View Invoice Details" position="left" >
                <vs-button
                  type="border"
                  size="small"
                  @click="viewInvoiceDetail(data[indextr].invoiceId)"
                  icon-pack="feather"
                  icon="icon-list"
                  class="mr-2"
                ></vs-button>
              </vx-tooltip>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
      <span class="mr-2">
        {{
          dataTableParams.page * dataTableParams.limit -
          (dataTableParams.limit - 1)
        }}
        -
        {{
          totalDocs - dataTableParams.page * dataTableParams.limit > 0
            ? dataTableParams.page * dataTableParams.limit
            : totalDocs
        }}
        of {{ totalDocs }}
      </span>
      <div class="pagination-div" v-if="serverResponded">
        <paginate
          :page-count="totalPage"
          :click-handler="handleChangePage"
          class="pagination"
          :page-range="9"
          :prevText="'<'"
          :nextText="'>'"
        ></paginate>
      </div>
    </div>
  </vx-card>
</template>

<script>
import vSelect from "vue-select";
import { mapActions } from "vuex";
import moment from "moment";

export default {
  name: "UpcomingInvoicesList",
  components: {
    "v-select": vSelect
  },
  props: ["invoiceData", "totalDocs", "page", "noDataText"],
  data() {
    return {
      isMounted: false,
      currentPage: 1,
      dataTableParams: {
        search: "",
        sort: "invoiceNumber",
        dir: "asc",
        page: 1,
        limit: 25,
        clinicId: this.clinicId,
      },
      limitOptions: [
        { text: "5", value: 5 },
        { text: "10", value: 10 },
        { text: "25", value: 25 },
        { text: "50", value: 50 },
        { text: "100", value: 100 }
      ],
      serverResponded: false,
      awaitingSearch: null,
      timer: ''
    };
  },
  methods: {
    moment(date) {
      return moment(date);
    },
    async fetchList() {
      this.$vs.loading();
      await this.$emit("getUpcomingInvoicesList", this.dataTableParams);
      this.serverResponded = true;
      this.$vs.loading.close();
    },
    viewInvoiceDetail(id) {
      this.$emit("viewUpcomingInvoiceDetail", id);
    },
    handleSearch(searching) {
      if (this.awaitingSearch) {
        clearTimeout(this.awaitingSearch);
        this.awaitingSearch = null;
      }
      this.awaitingSearch = setTimeout(() => {
        this.serverResponded = false;
        this.dataTableParams.search = searching;
        this.dataTableParams.page = 1;
        this.$refs.table.currentx = 1;
        this.fetchList();
      }, 500);
    },
    handleChangePage(page) {
      this.dataTableParams.page = page;
      this.fetchList();
    },
    handleSort(key, active) {
      this.serverResponded = false;
      this.dataTableParams.sort = key;
      this.dataTableParams.dir = active;
      this.dataTableParams.page = 1;
      this.$refs.table.currentx = 1;
      this.fetchList();
    },
    cancelAutoUpdate () {
      clearInterval(this.timer);
    }
  },
  beforeDestroy () {
    this.cancelAutoUpdate();
  },
  watch: {
    "dataTableParams.page": function(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.dataTableParams.page = newVal;
        this.fetchList();
      }
    },
    "dataTableParams.limit": function(newlimit, oldLimit) {
      if (newlimit !== oldLimit) {
        this.serverResponded = false;
        this.dataTableParams.page = 1;
        this.dataTableParams.limit = newlimit;
        this.fetchList();
      }
    },
    "$store.state.AppActiveClinicId": function(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.dataTableParams.activeClinicId = newVal;
        this.fetchList();
      }
    },
  },
  computed: {
    totalPage: function() {
      return this.totalDocs / this.dataTableParams.limit >
      parseInt(this.totalDocs / this.dataTableParams.limit)
        ? parseInt(this.totalDocs / this.dataTableParams.limit) + 1
        : parseInt(this.totalDocs / this.dataTableParams.limit);
    }
  },
  async created() {
    this.noDataText = "Loading...";
    this.dataTableParams.activeClinicId = this.$store.state.AppActiveClinicId;
    await this.fetchList();
    this.timer = setInterval(this.fetchList, 100000);
  }
};
</script>
