<template>
  <div>
    <UpcomingInvoicesList
      :invoiceData="invoiceData"
      :totalDocs="totalDocs"
      :page="page"
      :noDataText="noDataText"
      @getUpcomingInvoicesList="getUpcomingInvoicesList"
      @viewUpcomingInvoiceDetail="viewUpcomingInvoiceDetail"
    />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import UpcomingInvoicesList from "../../components/invoice/listUpcomingInvoices";

export default {
  components: {
    UpcomingInvoicesList
  },
  data() {
    return {
      totalDocs: 0,
      page: 1,
      invoiceData: [],
      noDataText: 'Loading...'
    };
  },
  methods: {
    ...mapActions("general", [
      "fetchAdminClinicUpcomingInvoice"
    ]),
    async getUpcomingInvoicesList(dataTableParams) {
      let self = this;
      this.fetchAdminClinicUpcomingInvoice(dataTableParams).then( async (res) => {
        self.invoiceData = res.data.data.docs;
        self.totalDocs = res.data.data.pagination.total || 0;
        self.page = res.data.data.pagination.page;
        self.currentPage = res.data.data.pagination.page;
        self.noDataText = 'No Upcoming Invoice Available';
      });
    },
    viewUpcomingInvoiceDetail(invoiceId) {
      this.$router.push({
        name: "UpcomingInvoiceDetails",
        params: { id: invoiceId }
      });
    }
  }
};
</script>
